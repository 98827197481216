<script setup>    
  import { ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { Format } from '@/format';
  //import { MapFromQuote } from '@/mappers/quote';
  import { useBackend } from '@/composables/Backend';
  import { useCartman } from '@/composables/Cartman';
  import PriceIncreaseAlert from '@/components/PriceIncreaseAlert.vue';
  import Product from '@/components/Product.vue';

  const router = useRouter();
  const route = useRoute();
  const backend = useBackend();
  const cartman = useCartman();
  
  const addToCartError = ref('');
  const isAddingToCart = ref(false);

  const quote = ref({
    id: route.query['id'],
    number: route.query['id'],
    title: '',
    date: '',
    customer: {
      name: '',
      email: '',
    },
    products: [],
    subtotal: null,
    discount: '',
    customerNotes: '',
  });
  
  backend.getQuote(quote.value.id)
    .then(result => {
      if (result && result.error) {
        // user isn't logged in, redirect to login form
        // TODO: should probably check the status and make sure it's an unauthorized error instead of a not found error
        router.push({ name: 'login' });
      }
      else {
        quote.value.title = result.quote.name;
        quote.value.date = result.quote.createdAt;
        quote.value.customer.name = `${result.quote.customer?.firstName } ${result.quote.customer?.lastName}`
        quote.value.customer.email = result.quote.customer?.email;
        quote.value.subtotal = result.quote.cost;
        quote.value.customerNotes = result.quote.customerNotes;

        result.quote.products.forEach(apiProduct => {
          // TODO: convert to our product data so we can show more user friendly data
          // quote.value.products.push(MapFromQuote.toProductData(apiProduct));
          
          quote.value.products.push({
            id: apiProduct.productId,
            heading: apiProduct.type,
            windowName: apiProduct.label,
            imageUrl: apiProduct.imageUrl,
            cost: apiProduct.cost,
            quantity: apiProduct.quantity,
            totalCost: apiProduct.cost * apiProduct.quantity,
            configuration: Object.entries(apiProduct.params).map(kv => {
              return { label: kv[0], value: kv[1] }
            })
          });
        });
      }      
    });  

    function doAddToCart(quoteId) {
      addToCartError.value = '';
      isAddingToCart.value = true;

      backend.addQuoteToCart(quoteId)
      .then(result => {
        if (result && result.success) {
          // refresh cart data since this endpoint doesn't return the updated cart
          cartman.shouldReload.value = true;

          // show cart          
          cartman.isCartShown.value = true;
        }
        else {
          if (result.error) {
            addToCartError.value = result.error;            
          }
          else {
            addToCartError.value = 'An error occurred adding the quote to your cart. Please try again later or contact support.'
          }
        }
      })
      .finally(() => {
        isAddingToCart.value = false; // Reset the button state
      });
    }
</script>

<template>
  <div class="container quote-view">
    <div class="row">
      <div class="col">
        <div>
          <a href="/my-account">Back to Account</a>
          <h2>Quote {{ quote.title }}</h2>
        </div>

        <div class="card quote--card">
          <div class="card__tag-corner">
            <div class="card__tag">Quote</div>
          </div>

          <label class="quote__id">Quote ID: {{ quote.number }}</label>

          <div class="quote--column">
            <label class="pretext">Date</label>
            <span class="quote__customer-detail ts--callout no--margin-b">{{ Format.asDate(quote.date) }}</span>
          </div>

          <div class="quote--column">
            <label class="pretext">Prepared For</label>
            <span class="ts--callout no--margin-b">{{ quote.customer.name }}</span>
            <span class="quote__customer-detail ts--callout no--margin-b">{{ quote.customer.email }}</span>
          </div>

          <price-increase-alert></price-increase-alert>

          <template v-for="product in quote.products" :key="product.itemId">
            <product :data="product"></product>
          </template>

          <hr>

          <!-- <div class="quote__totals">
            <div>
              <label class="quote__total-label">Subtotal: </label>
              <span class="quote__total-item">${{ quote.subtotal }}</span>
            </div>
          </div> -->
          <div class="quote__see-total">
            <span>See Final price in cart</span>
          </div>

          <div v-if="addToCartError">{{ addToCartError }}</div>
          <div class="quote_btn-wrapper">
            <button class="quote_button-view" @click="doAddToCart(quote.id)" :disabled="isAddingToCart">
              <!-- Add All Items to Cart → -->
              {{ isAddingToCart ? 'Adding...' : 'Add All Items to Cart →' }}
            </button>
          </div>
        </div>
        
      </div>
    </div>
  </div>
  
</template>